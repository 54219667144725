<template>
	<div class="test-videojs">
		<video id="videoPlayer" class="video-js" crossOrigin="anonymous" muted></video>
	</div>
</template>
<script>
	// import Videojs from "video.js"; // 引入Videojs
	export default {
		data() {
			return {
				// // nowPlayVideoUrl:"https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8" //是一段视频，可将cctv1 （是live现场直播，不能快退）的替换为它，看到快进快退的效果
				nowPlayVideoUrl:"rtmp://rtmp.coding21.com/live/qwerty",
				// nowPlayVideoUrl:"https://rtmp.coding21.com/hls/abcc.m3u8"
			};
		},
		// props:{
		// 	nowPlayVideoUrl: {
		// 		type:String,
		// 		required:true,
				
		// 	}
		// },
		mounted() {
			this.initVideo(this.nowPlayVideoUrl);
		},
		methods: {
			// initVideo(nowPlayVideoUrl) {
			// 	console.log('nowPlayVideoUrl', nowPlayVideoUrl)
			// 	// 这些options属性也可直接设置在video标签上，见 muted
			// 	let options = {
			// 		autoplay: true, // 设置自动播放
			// 		controls: true, // 显示播放的控件
			// 		bigPlayButton: false,
			// 		textTrackDisplay: false,
			// 		posterImage: true,
			// 		errorDisplay: false,
			// 		// controlBar: false,
			// 		sources: [
			// 			// 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
			// 			{
			// 				src: nowPlayVideoUrl,
			// 				type: "application/x-mpegURL" // 告诉videojs,这是一个hls流
			// 			}
			// 		]
			// 	};
			// 	// videojs的第一个参数表示的是，文档中video的id
			// 	const myPlyer = Videojs("videoPlayer", options, function onPlayerReady() {
			// 		console.log("onPlayerReady 中的this指的是：", this); // 这里的this是指Player,是由Videojs创建出来的实例
			// 		console.log(myPlyer === this); // 这里返回的是true
			// 	});
			// }
		}
	};
</script>
<style lang="scss">
	.test-videojs {
		width:1000px;
		height: 800px;
		margin: 0 auto;
	}

	#videoPlayer {
		width: 100%;
		height: 100%;
	}
</style>
